import { CodeLanguage } from '../../components/inputs/CodeInput';

const arrayMustNotBeEmpty = (values: []) => {
  return values?.length > 0 || 'Please select at least one';
};
export const timeIn24HourFormat = (value: string) => {
  return (
    value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) ||
    'Time must be entered in 24hr format HH:MM'
  );
};
export const minifyCode = (code: string, format: CodeLanguage): string => {
  switch (format) {
    case CodeLanguage.JSON:
      if (!code || code.trim() === '') return '{}'; // Default "empty" for JSON
      return JSON.stringify(JSON.parse(code));
    default:
      return code;
  }
};
export const formatCode = (code: string, format: CodeLanguage): string => {
  let resultCode = '<>'; // Default "empty" for all languages
  switch (format) {
    case CodeLanguage.JSON:
      if (!code || code.trim() === '') resultCode = '{}'; // Default "empty" for JSON
      else resultCode = JSON.stringify(JSON.parse(code), null, 2);
      break;
    default:
      resultCode = code;
      break;
  }
  return resultCode;
};
export const validateCodeInput = (codeToValidate: string, codeLanguage: CodeLanguage): boolean => {
  let isValid = true;
  if (!codeToValidate || codeToValidate.trim() === '') return true; // Empty code is valid
  try {
    formatCode(codeToValidate, codeLanguage);
    isValid = true;
  } catch (e) {
    isValid = false;
  }
  return isValid;
};
export const urlMustBeValid = (value: string) => {
  let givenURL;
  try {
    givenURL = new URL(value);
  } catch (error) {
    return 'The url must be valid and contain http or https.';
  }
  return givenURL.protocol === 'http:' || givenURL.protocol === 'https:'
    ? true
    : 'The url must contain http or https.';
};
export default arrayMustNotBeEmpty;
