import React, { useContext } from 'react';
import { IFcwPolicy } from '../../utils/types/IFcwPolicy';
import UserContext from '../../utils/authorization/UserContext';
import hasAccess from '../../utils/authorization/authorizationCheck';

interface ICanAccessProps {
  requestedAction: string;
  children: JSX.Element;
  notAuthorizedChildren?: JSX.Element;
}

const CanAccess: React.FunctionComponent<ICanAccessProps> = ({
  requestedAction,
  children,
  notAuthorizedChildren,
}: ICanAccessProps): JSX.Element => {
  const policies: IFcwPolicy[] = useContext(UserContext);

  const fragment: JSX.Element = hasAccess(policies, requestedAction)
    ? children
    : notAuthorizedChildren;

  return fragment || null;
};

export default CanAccess;
