import { gql } from '@apollo/client';

export const ProjectNileWorkflowsEndpoint = '/listBlobsInContainer';

export const GetProjectNileWorkflows = gql`
  query ($keyword: String!) {
    projectNileWorkflows(keyword: $keyword) {
      id
      name
      parameters
    }
  }
`;
