import { ColumnActionsMode, IColumn } from '@fluentui/react';
import React, { useContext } from 'react';
import FacepileWrapper from '../../common/lists/FacepileWrapper';
import { sharedLastModified } from '../../common/lists/SharedDataGridColumns';
import { rowClasses } from '../../common/styles/CommonStyleObjects';
import { DateConstants, TimezoneConstants } from '../../utils/formatters/DateConstants';
import enumFormatter from '../../utils/formatters/EnumFormatter';
import linkFormatter from '../../utils/formatters/LinkFormatter';
import { IBaseTask } from '../../utils/types/IBaseTask';
import { IStagedTask } from '../../utils/types/ITask';
import { getCountriesLabel } from './details/TaskDetailLocationCard';
import { TaskType } from './list/BaseTaskListfilters.config';
import { momentDateToTimezone } from '../../utils/formatters/DateFormatters';
import AppConfigValues from '../../utils/appConfigValues/AppConfigValues';
import AppConfigValueContext from '../../utils/appConfigValues/AppConfigValuesContext';

export interface IBaseTaskListColumns {
  onColumnClick?: () => void;
  taskType: TaskType;
  onDetailsClick?: (task: IBaseTask) => void;
}

const getBaseTaskListColumns = (props: IBaseTaskListColumns): IColumn[] => {
  const appConfigValues: AppConfigValues = useContext(AppConfigValueContext);
  const { flags } = appConfigValues;

  return [
    {
      key: 'id',
      fieldName: 'id',
      name: 'Task ID',
      maxWidth: 60,
      minWidth: 60,
      className: rowClasses.medFont,
    },
    {
      key: 'name',
      fieldName: 'name',
      name: 'Task name',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'description',
      fieldName: 'description',
      name: 'Description',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'dueTime',
      fieldName: 'dueTime',
      name: 'Time due',
      maxWidth: 125,
      minWidth: 125,

      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'operationsDetail.opsOrg.opsSummary.name',
      fieldName: 'operationsDetail',
      name: 'Ops summary',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IBaseTask) => {
        return <span>{item.operationsDetail?.opsOrg?.opsSummary?.name}</span>;
      },
    },
    {
      key: 'operationsDetail.opsOrg.name',
      fieldName: 'operationsDetail',
      name: 'Ops org',
      maxWidth: 125,
      minWidth: 125,

      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return <span>{item.operationsDetail?.opsOrg?.name}</span>;
      },
    },
    {
      key: 'operationsDetail.name',
      fieldName: 'operationsDetail',
      name: 'Ops detail',
      maxWidth: 125,
      minWidth: 125,

      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return <span>{item.operationsDetail?.name}</span>;
      },
    },
    // how to sort cols with multiple values in cells
    {
      key: 'countries',
      fieldName: 'companies',
      name: 'Country',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IBaseTask) => {
        const companiesLabel = getCountriesLabel(item.companies);
        return <span>{companiesLabel}</span>;
      },
    },
    {
      key: 'companies',
      fieldName: 'companies',
      name: 'Company code',
      maxWidth: 125,
      minWidth: 125,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IBaseTask) => {
        return item.companies
          ?.map((cc) => (cc.companyCode === 0 ? 'All Active' : cc.companyCode))
          .join(', ');
      },
    },
    {
      key: 'functionsSummaries',
      fieldName: 'functionsSummaries',
      name: 'Functions summaries',
      maxWidth: 125,
      minWidth: 125,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IBaseTask) => {
        return item.functionsSummaries?.map((fs) => fs.name).join(', ');
      },
    },
    {
      key: 'level.name',
      fieldName: 'level',
      name: 'Task level',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IBaseTask) => {
        return <span>{item.level?.name}</span>;
      },
    },
    {
      key: 'type.name',
      fieldName: 'type',
      name: 'Task type',
      maxWidth: 125,
      minWidth: 125,

      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return item.type?.name;
      },
    },
    {
      key: 'scopeOfActivity.name',
      fieldName: 'scopeOfActivity',
      name: 'Scope of activity',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return item.scopeOfActivity?.name;
      },
    },
    {
      key: 'estimatedTime',
      fieldName: 'estimatedTime',
      name: 'Estimated time',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
    },
    {
      key: 'publishType',
      fieldName: 'publishType',
      name: 'Publish type',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      onRender: (item: IBaseTask) => {
        return enumFormatter(item?.publishType);
      },
    },
    {
      key: 'dataSources',
      fieldName: 'dataSources',
      name: 'Data sources',
      maxWidth: 250,
      minWidth: 250,
      className: rowClasses.medFont,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IBaseTask) => {
        return item.dataSources?.map((ds) => ds.name).join(', ');
      },
    },
    {
      key: 'primaries',
      name: 'Primary task owner',
      fieldName: 'primaries',
      ariaLabel: 'Column operations for Primaries column, press to sort on Primaries',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IBaseTask) => {
        return <FacepileWrapper users={item.primaries} ariaLabel="Primary Task Owner(s) list" />;
      },
    },
    {
      key: 'backupTaskPerformers',
      name: 'Back-up task owner',
      fieldName: 'backupTaskPerformers',
      ariaLabel:
        'Column operations for BackupTaskPerformers column, press to sort on BackupTaskPerformers',
      columnActionsMode: ColumnActionsMode.disabled,
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return (
          <FacepileWrapper
            users={item.backupTaskPerformers}
            ariaLabel="Back-up Task Owner(s) list"
          />
        );
      },
    },
    {
      key: 'stakeholders',
      name: 'Stakeholders',
      fieldName: 'stakeholders',
      ariaLabel: 'Column operations for Stakeholders column, press to sort on Stakeholders',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IBaseTask) => {
        return <FacepileWrapper users={item.stakeholders} ariaLabel="Stakeholders list" />;
      },
    },
    {
      key: 'supplier.name',
      fieldName: 'supplier',
      name: 'Supplier',

      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return item.supplier?.name;
      },
    },
    {
      key: 'timeZone.name',
      fieldName: 'timeZone',
      name: 'Work timezone',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IBaseTask) => {
        return item.timeZone?.name;
      },
    },

    {
      key: 'dtpDocLink',
      fieldName: 'dtpDocLink',
      name: 'DTP document link',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IBaseTask) => {
        return linkFormatter(item?.dtpDocLink);
      },
    },
    {
      key: 'dtpUpdateDate',
      name: 'DTP update date',
      maxWidth: 180,
      minWidth: 180,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IBaseTask) => {
        // To Do: Revisit once we figure out where to implement timezone rendering
        return momentDateToTimezone(
          item.dtpUpdateDate,
          DateConstants.DayBreakoutWithShortTimeAndTimezone,
          TimezoneConstants.PST,
          true,
        );
      },
    },
    {
      key: 'dependencyOnStagedTasks',
      fieldName: 'dependencyOnStagedTasks',
      name: 'Dependency on task ID',
      columnActionsMode: ColumnActionsMode.disabled,
      maxWidth: 145,
      minWidth: 145,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return (
          (item?.dependencyOnStagedTasks &&
            item?.dependencyOnStagedTasks.length > 0 &&
            item.dependencyOnStagedTasks?.map((task: IBaseTask) => {
              return (
                <a
                  href={`/stagedTasks/edit/${task.id}`}
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                  target="_blank"
                  rel="noreferrer"
                  style={{ padding: ' 0px 5px' }}
                >
                  {task.id}
                </a>
              );
            })) ||
          '--'
        );
      },
    },
    {
      key: 'dependencyForStagedTasks',
      fieldName: 'dependencyForStagedTasks',
      name: 'Dependency for task ID',
      maxWidth: 145,
      minWidth: 145,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return (
          (item?.dependencyForStagedTasks &&
            item?.dependencyForStagedTasks.length > 0 &&
            item.dependencyForStagedTasks.map((task: IStagedTask) => {
              return (
                <a
                  href={`/stagedTasks/edit/${task.id}`}
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                  target="_blank"
                  rel="noreferrer"
                  style={{ padding: ' 0px 5px' }}
                >
                  {task.id}
                </a>
              );
            })) ||
          '--'
        );
      },
    },
    {
      key: 'bcpCategory.name',
      fieldName: 'bcpCategory',
      name: 'BCP category',

      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return item?.bcpCategory?.name;
      },
    },
    {
      key: 'bcpPriorityPeak',
      fieldName: 'bcpPriorityPeak',
      name: 'BCP peak',

      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,

      onRender: (item: IBaseTask) => {
        return enumFormatter(item?.bcpPriorityPeak);
      },
    },
    {
      key: 'bcpPriorityNonPeak',
      fieldName: 'bcpPriorityNonPeak',
      name: 'BCP non-peak',

      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,

      onRender: (item: IBaseTask) => {
        return enumFormatter(item?.bcpPriorityNonPeak);
      },
    },
    {
      key: 'taskTags',
      fieldName: 'taskTags',
      name: 'Tags',
      maxWidth: 125,
      minWidth: 125,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      onRender: (item: IBaseTask) => {
        return item.taskTags?.map((tt) => tt.name).join(', ');
      },
    },
    {
      key: 'reportType.name',
      fieldName: 'reportType',
      name: 'Report type',

      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return item.reportType?.name;
      },
    },
    {
      key: 'analysisType.name',
      fieldName: 'analysisType',
      name: 'Analysis type',

      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return item.analysisType?.name;
      },
    },
    {
      key: 'output.name',
      fieldName: 'output',
      name: 'Output',

      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,

      onRender: (item: IBaseTask) => {
        return item.output?.name;
      },
    },
    ...(flags.ProjectNileWorkflows
      ? [
          {
            key: 'workflowId',
            fieldName: 'workflowId',
            name: 'Workflow',
            maxWidth: 125,
            minWidth: 125,
            className: rowClasses.medFont,
            isResizable: true,
            onRender: (item: IBaseTask) => {
              return item.workflowName;
            },
          },
        ]
      : []),

    ...(flags.SalesLocationOnTasks
      ? [
          {
            key: 'salesLocations',
            fieldName: 'salesLocations',
            name: 'Sales Locations',
            maxWidth: 125,
            minWidth: 125,
            columnActionsMode: ColumnActionsMode.disabled,
            className: rowClasses.medFont,
            isResizable: true,
            onRender: (item: IBaseTask) => {
              return item.salesLocations?.map((s) => s.name).join(', ');
            },
          },
        ]
      : []),
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
  ];
};
export default getBaseTaskListColumns;
