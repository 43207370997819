import { IconButton, Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { formGridRowStyles } from '../../../common/styles/CommonStyleObjects';
import FormGridCard from '../../../common/formFields/FormGridCard';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import FormGridColumn, { FormGridColumnWidth } from '../../../common/formFields/FormGridColumn';
import NileWorkflowSelector from '../../../common/formFields/NileWorkflowSelector';
import { IProjectNileWorkflow } from '../../../utils/types/IProjectNileWorkflow';
import CodeInputPanel from '../edit/CodeInputPanel';
import { CodeLanguage } from '../../inputs/CodeInput';
import { minifyCode } from '../../../utils/validation/CustomValidations';
import CanAccess from '../../authentication/CanAccess';

interface ITaskDetailWorkflowConfigurationCardProps {
  form: UseFormMethods<IBaseTask>;
}
const TaskDetailWorkflowConfigurationCard = (
  props: ITaskDetailWorkflowConfigurationCardProps,
): JSX.Element => {
  const { form } = props;
  const { control, setValue, watch } = form;
  const [codeInputPanelOpen, setCodeInputPanelOpen] = useState(false);

  // Watch for changes in the workflow
  const workflow = watch('workflow');

  const onWorkflowSelected = (selectedWorkflow: IProjectNileWorkflow): void => {
    setValue('workflow', selectedWorkflow);
  };
  const onWorkflowParametersSave = (parameters: string) => {
    parameters = minifyCode(parameters, CodeLanguage.JSON);
    workflow.parameters = parameters; // Update the current workflow's parameters
    setValue('workflow', workflow);
    setCodeInputPanelOpen(false);
  };

  return (
    <FormGridCard cardLabel="Automation">
      <>
        <Stack horizontal wrap styles={formGridRowStyles}>
          <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
            <Controller
              name="workflow"
              label="Workflow"
              render={() => {
                return (
                  <NileWorkflowSelector
                    defaultValue={workflow?.id && workflow?.name ? [workflow] : []}
                    onChange={(newNileWorkflows: IProjectNileWorkflow[]): void => {
                      const selectedWorkflow = newNileWorkflows[0];
                      onWorkflowSelected(selectedWorkflow);
                    }}
                  />
                );
              }}
              control={control}
            />
          </FormGridColumn>
          <CanAccess requestedAction="EditTaskWorkflowParameters">
            <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
              <Stack horizontal>
                <Controller
                  name="workflowParameters"
                  control={control}
                  render={() => (
                    <TextField
                      value={workflow?.parameters}
                      style={{
                        width: 500,
                        wordBreak: 'break-all', // Ensure text wraps even with no spaces
                      }}
                      label="Workflow Parameters"
                      placeholder="{}"
                      resizable={false}
                      disabled={false}
                      readOnly
                      multiline
                      rows={6}
                      onClick={() => setCodeInputPanelOpen(true)}
                    />
                  )}
                />
                <IconButton
                  title="Update Workflow Parameters"
                  ariaLabel="Update Workflow Parameters"
                  iconProps={{
                    iconName: 'Code',
                  }}
                  onClick={() => setCodeInputPanelOpen(true)}
                />
              </Stack>
            </FormGridColumn>
          </CanAccess>
        </Stack>
        <CanAccess requestedAction="EditTaskWorkflowParameters">
          {codeInputPanelOpen && (
            <CodeInputPanel
              code={workflow?.parameters}
              language={CodeLanguage.JSON}
              label={`Workflow Parameters (${CodeLanguage.JSON})`}
              onSave={(currentCode: string) => {
                onWorkflowParametersSave(currentCode);
              }}
              onClose={() => {
                setCodeInputPanelOpen(false);
              }}
            />
          )}
        </CanAccess>
      </>
    </FormGridCard>
  );
};
export default TaskDetailWorkflowConfigurationCard;
