import React, { useRef, useEffect } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { FontSizes, FontWeights, mergeStyleSets } from '@fluentui/react';
import { formatCode } from '../../utils/validation/CustomValidations';

export enum CodeLanguage {
  PYTHON = 'python',
  SQL = 'sql',
  R = 'r',
  JAVA = 'java',
  CSHARP = 'csharp',
  JSON = 'json',
}

const styles = mergeStyleSets({
  container: {
    height: 360,
    border: '1px solid #ccc',
    borderRadius: 2,
  },
  errorContainer: {
    height: 360,
    border: '1px solid red',
  },
  labelStyles: {
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.size14,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
});

interface CodeInputProps {
  code: string;
  language: CodeLanguage;
  disabled: boolean;
  hasError?: boolean;
  onChange: (value: string) => void;
}

const CodeInput: React.FC<CodeInputProps> = ({ code, language, disabled, hasError, onChange }) => {
  const editorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      const editor = monaco.editor.create(editorRef.current, {
        value: formatCode(code, language),
        language,
        theme: 'light',
        formatOnPaste: true,
        readOnly: disabled,
        minimap: { enabled: false },
      });

      editor.onDidChangeModelContent(() => {
        const currentValue = editor.getValue();
        onChange(currentValue);
      });
    }
  }, [editorRef]);

  return <div className={hasError ? styles.errorContainer : styles.container} ref={editorRef} />;
};

export default CodeInput;
