import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { EditStagedTask, GetStagedTask } from '../../../utils/api/StagedTaskApi';
import { IStagedTask, IStagedTaskRouteParams } from '../../../utils/types/ITask';
import PanelTypes from '../../../utils/types/PanelTypes';
import StagedTasksDetailForm from './StagedTasksDetailForm';

const StagedTasksEdit = (): JSX.Element => {
  const { stagedTaskId } = useParams<IStagedTaskRouteParams>();
  const { data, loading, error } = useQuery<{ stagedTask: IStagedTask }>(GetStagedTask, {
    variables: { stagedTaskId: parseInt(stagedTaskId, 10) },
  });
  const [stagedTask, setStagedTask] = React.useState<IStagedTask | null>(null);

  // useEffect added here to ensure form is correctly initialized with steged task values
  useEffect(() => {
    if (data) {
      setStagedTask(data.stagedTask as IStagedTask);
    }
  }, [data]);

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} label="Loading staged task item" />
      {stagedTask && !loading && (
        <StagedTasksDetailForm
          panelMode={PanelTypes.Edit}
          stagedTask={stagedTask}
          mutation={EditStagedTask}
        />
      )}
    </>
  );
};

export default StagedTasksEdit;
