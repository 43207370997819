import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanel } from '@coherence-design-system/controls';
import { FontSizes, FontWeights, mergeStyleSets, Stack, Label } from '@fluentui/react';
import ActionButtons from '../../../common/buttons/ActionButtons';
import { CoherencePanelStyles } from '../../../common/styles/CommonStyleObjects';
import { validateCodeInput } from '../../../utils/validation/CustomValidations';
import CodeInput, { CodeLanguage } from '../../inputs/CodeInput';

const styles = mergeStyleSets({
  container: {
    height: 360,
    border: '1px solid #ccc',
    borderRadius: 2,
  },
  labelStyles: {
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.size14,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
});

interface ICodeInputPanelProps {
  code: string;
  language: CodeLanguage;
  label: string;
  onSave: (code: string) => void;
  onClose: () => void;
}
interface IComponentState {
  currentCode: string;
  isCodeValid: boolean;
}

const CodeInputPanel = (props: ICodeInputPanelProps): JSX.Element => {
  const { code, language, label, onSave, onClose } = props;
  const form = useForm();
  const [componentState, setComponentState] = React.useState({
    currentCode: code,
    isCodeValid: validateCodeInput(code, language),
  });
  const updateComponentState = (newComponentState: IComponentState) => {
    setComponentState((currentComponentState) => {
      return { ...currentComponentState, ...newComponentState };
    });
  };

  const { handleSubmit } = form;
  const handleOnSave = () => {
    onSave(componentState.currentCode);
    onClose();
  };

  const onCodeChange = (updatedCode: string) => {
    updateComponentState({
      ...componentState,
      currentCode: updatedCode,
      isCodeValid: validateCodeInput(updatedCode, language),
    });
  };

  return (
    <CoherencePanel
      panelSize="600px"
      titleText="Code Input"
      isOpen
      onDismiss={onClose}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Code Input Panel"
      onRenderFooter={(): JSX.Element => (
        <Stack>
          <ActionButtons
            mutationLoading={false}
            closePanel={onClose}
            disabled={!componentState.isCodeValid}
            handleSubmit={handleSubmit(handleOnSave)}
            saveLabel="Save"
            saveTitle="Save"
            cancelLabel="Cancel"
            cancelTitle="Cancel"
          />
        </Stack>
      )}
    >
      <Stack verticalFill>
        {label && (
          <Label className={styles.labelStyles} disabled={false}>
            {label}
          </Label>
        )}
        <CodeInput
          code={componentState.currentCode}
          language={language}
          disabled={false}
          hasError={!componentState?.isCodeValid}
          onChange={onCodeChange}
        />
      </Stack>
    </CoherencePanel>
  );
};

export default CodeInputPanel;
