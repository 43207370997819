import {
  ColumnActionsMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  IContextualMenuItem,
} from '@fluentui/react';
import React from 'react';
import {
  sharedEditButton,
  sharedIsActive,
  sharedLastModified,
} from '../../../common/lists/SharedDataGridColumns';
import { rowClasses } from '../../../common/styles/CommonStyleObjects';
import { ITaskPropertyValue } from '../../../utils/types/ITaskProperty';
import DownloadButton, { IDownloadButtonProps } from '../../buttons/DownloadButton';

export const getTaskPropertyColumnList = (
  onEditClick: (item: ITaskPropertyValue) => void,
): IColumn[] => {
  return [
    sharedEditButton<ITaskPropertyValue>(onEditClick, false, 'name'),
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
    },
    sharedIsActive<{ [key: string]: boolean }>('isActive'),
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
  ];
};
export const getEditableTaskPropertyColumnList = (
  onEditClick: (item: ITaskPropertyValue) => void,
): IColumn[] => {
  return [
    {
      key: 'edit',
      name: 'Edit',
      maxWidth: 50,
      minWidth: 50,
      className: rowClasses.medFont,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: ITaskPropertyValue) => {
        return (
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            title="edit"
            disabled={item.isEditable === false}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEditClick(item);
            }}
          />
        );
      },
    },
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
    },
    sharedIsActive<{ [key: string]: boolean }>('isActive'),
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
    {
      key: 'isEditable',
      name: 'Is Editable',
      maxWidth: 170,
      minWidth: 170,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: ITaskPropertyValue) => {
        const flag = item.isEditable === true ? 'Yes' : 'No';
        return flag;
      },
    },
  ];
};
export const getTaskPropertiesMenu = (
  onAddItem: () => void,
  onRefetch: () => void,
  exportButtonProps: IDownloadButtonProps,
) => {
  const listCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'New Item',
      cacheKey: 'newItem', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      onClick: onAddItem,
    },
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefetch,
    },
    {
      key: 'exportToExcel',
      text: 'Export to Excel',
      cacheKey: 'export', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => {
        return (
          <DownloadButton
            text="Export to Excel"
            renderedInOverflow={item.renderedInOverflow}
            {...exportButtonProps}
          />
        );
      },
      fileEndpoint: exportButtonProps.fileEndpoint,
      fileName: exportButtonProps.fileName,
      postBody: exportButtonProps.postBody,
    },
  ];
  return listCommandBarItems;
};
