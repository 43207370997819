import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import {
  Stack,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  DefaultButton,
  IDropdownOption,
  SpinButton,
  TextField,
  Text,
  Label,
} from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import InlineFormInputErrorMessage from '../../../common/errorContent/InlineFormInputErrorMessage';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import ChangeStateDropdown from '../../../common/formFields/ChangeStateDropdown';
import TaskPropertyDropdown from '../../../common/formFields/TaskPropertyValuesDropdown';
import { LabelTooltipHorizontal } from '../../../common/labels/LabelTooltip';
import {
  GetChangeStateRequiredInputs,
  GetPublishedTask,
  GetPublishedTaskActions,
  GetPublishedTasks,
  TaskChangeState,
} from '../../../utils/api/PublishedTaskApi';
import enumFormatter from '../../../utils/formatters/EnumFormatter';
import { IPublishedTaskFilterIds } from '../../../utils/types/ITask';
import { TaskChangeStateOption } from '../../../utils/types/ITaskChangeStateOption';

export enum ChangeStateMode {
  ChangeStateSelected,
  ChangeStateAllFiltered,
  ChangeStateAll,
  ChangeStateCopy,
}

const spinButtonInputWrapperStyles: React.CSSProperties = {
  width: 160,
  marginRight: 15,
};

interface ITasksChangeStateProps {
  selectedTasks: number[];
  keyword?: string;
  filters?: IPublishedTaskFilterIds;
  taskStateFilters?: string[];
  onChangeStateComplete: () => void;
  changeStateMode: ChangeStateMode;
  selectedTasksCount: number;
  hideModal: () => void;
  refetchPublishedTaskId?: number;
  changeStateOptionsJEMTaskType?: boolean;
}
interface IChangeStateInputs {
  manualTime: number;
  automatedTime: number;
  reasonReactivatedId: number;
  reasonNotPerformedId: number;
  reasonMissedSLAId: number;
  notes: string;
}

const TasksChangeState = (props: ITasksChangeStateProps): JSX.Element => {
  const {
    selectedTasksCount,
    selectedTasks,
    keyword,
    filters,
    taskStateFilters,
    onChangeStateComplete,
    changeStateMode,
    hideModal,
    refetchPublishedTaskId,
    changeStateOptionsJEMTaskType,
  } = props;
  const [pageState, setPageState] = useState({
    isTimeTakenRequired: false,
    isTimeTakenOptional: false,
    isReasonMissedSLARequired: false,
    isReasonNotPerformedRequired: false,
    isReasonReactivatedRequired: false,
    isNotesAvailable: false,
    manualTime: 0,
    autoTime: 0,
  });
  const form = useForm<IChangeStateInputs>({ defaultValues: { manualTime: 0, automatedTime: 0 } });
  const { setValue, control, handleSubmit, errors } = form;
  const refetchQueries =
    refetchPublishedTaskId > 0
      ? [
          { query: GetPublishedTask, variables: { publishedTaskId: refetchPublishedTaskId } },
          {
            query: GetPublishedTaskActions,
            variables: { publishedTaskId: refetchPublishedTaskId },
          },
        ]
      : [{ query: GetPublishedTasks }];

  const [changeStateTasks, { loading, error }] = useMutation(TaskChangeState, {
    refetchQueries,
    onCompleted: () => {
      setPageState({
        ...pageState,
        isTimeTakenRequired: false,
        isTimeTakenOptional: false,
        isReasonMissedSLARequired: false,
        isReasonNotPerformedRequired: false,
        isReasonReactivatedRequired: false,
        isNotesAvailable: false,
      });
      onChangeStateComplete();
    },
  });

  const [selectedState, setSelectedState] = useState<TaskChangeStateOption>(null);
  const getModeVariables = () => {
    const addTaskStateFilters = filters;
    if (addTaskStateFilters) {
      addTaskStateFilters.taskState = taskStateFilters;
    }
    return {
      selectedItems: changeStateMode === ChangeStateMode.ChangeStateSelected ? selectedTasks : null,
      keyword: changeStateMode === ChangeStateMode.ChangeStateAllFiltered ? keyword : null,
      filters: changeStateMode === ChangeStateMode.ChangeStateAllFiltered ? filters : null,
    };
  };

  const [loadRequiredInputs, { loading: loadingRequiredInputs, error: errorRequiredInputs }] =
    useLazyQuery(GetChangeStateRequiredInputs, {
      variables: {
        changeStateTaskDto: {
          ...getModeVariables(),
          taskState: selectedState,
        },
      },
      fetchPolicy: 'network-only',
    });

  const submitForm = (formData: { [key: string]: string }) => {
    changeStateTasks({
      variables: {
        changeStateTaskDto: {
          ...getModeVariables(),
          taskState: selectedState,
          manualTime: parseInt(formData.manualTime, 10),
          automatedTime: parseInt(formData.automatedTime, 10),
          reasonMissedSLAId: parseInt(formData.reasonMissedSLAId, 10),
          reasonNotPerformedId: parseInt(formData.reasonNotPerformedId, 10),
          reasonReactivatedId: parseInt(formData.reasonReactivatedId, 10),
          notes: formData.notes,
        },
      },
    });
  };

  const getMessage = () => {
    if (changeStateMode === ChangeStateMode.ChangeStateCopy) {
      return (
        <div>
          Click Set task state button to set this task to{' '}
          <strong>{enumFormatter(selectedState.toString())}</strong>.
        </div>
      );
    }

    return (
      <div>
        Click Change tasks state button to change <strong>{selectedTasksCount}</strong> tasks to{' '}
        {enumFormatter(selectedState.toString())}.
      </div>
    );
  };
  useEffect(() => {
    if (selectedState) {
      loadRequiredInputs({
        variables: {
          changeStateTaskDto: {
            ...getModeVariables(),
            taskState: selectedState,
          },
        },
        onCompleted: (data) => {
          const {
            isReasonMissedSLARequired,
            isReasonNotPerformedRequired,
            isReasonReactivatedRequired,
            isTimeTakenRequired,
            isTimeTakenOptional,
          } = data?.changeStateRequiredFields;
          setPageState({
            ...pageState,
            isReasonMissedSLARequired,
            isReasonNotPerformedRequired,
            isReasonReactivatedRequired,
            isTimeTakenRequired,
            isTimeTakenOptional,
            isNotesAvailable: true,
          });
        },
      });
    }
  }, [selectedState]);

  return (
    <CoherenceModal
      isOpen
      modalWidth="small"
      height="responsive"
      title={`${changeStateMode === ChangeStateMode.ChangeStateCopy ? 'Set' : 'Change'} task state`}
      onDismiss={() => {
        setPageState({
          ...pageState,
          isTimeTakenRequired: false,
          isTimeTakenOptional: false,
          isReasonMissedSLARequired: false,
          isReasonNotPerformedRequired: false,
          isReasonReactivatedRequired: false,
          isNotesAvailable: false,
        });
        hideModal();
      }}
    >
      <Stack styles={{ root: { marginTop: 10 } }}>
        <Stack tokens={{ childrenGap: 10 }}>
          <ChangeStateDropdown
            label="State to change to"
            required
            id="changeStateKey"
            valueKey="changeStateKey"
            width="100%"
            handleChange={(newValue: IDropdownOption, valueKey: string): void => {
              setSelectedState(newValue.key as TaskChangeStateOption);
            }}
            changeStateOptionsJEMTaskType={changeStateOptionsJEMTaskType}
          />
          <LoadingErrorMessage
            loading={loadingRequiredInputs}
            error={errorRequiredInputs}
            label="Loading required inputs"
          />
          {pageState.isTimeTakenOptional && (
            <Stack
              horizontal
              wrap
              styles={{ root: { padding: '20px 0' } }}
              tokens={{ childrenGap: 10 }}
            >
              <Controller
                name="manualTime"
                control={control}
                render={({ value }) => (
                  <div style={spinButtonInputWrapperStyles}>
                    <LabelTooltipHorizontal
                      id="manualTime"
                      label="Manual Time"
                      tooltipMessage="Please enter the manual time that it took to change to the selected state in minutes"
                    />
                    <SpinButton
                      id="manualTimeInput"
                      defaultValue={value}
                      max={10000}
                      min={0}
                      ariaDescribedBy="manualTimeLabel"
                      aria-labelledby="manualTimeLabel"
                      decrementButtonAriaLabel="Decrement manual time taken"
                      incrementButtonAriaLabel="Increment manual time taken"
                      inputProps={{ 'aria-labelledby': 'manualTimeLabel' }}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        newValue?: string,
                      ): void => {
                        setPageState({
                          ...pageState,
                          manualTime: parseInt(newValue, 10),
                        });
                        return setValue('manualTime', newValue);
                      }}
                    />
                    <InlineFormInputErrorMessage errorMessage={errors?.manualTime?.message} />
                  </div>
                )}
              />
              <Controller
                name="automatedTime"
                control={control}
                render={({ value }) => (
                  <div style={spinButtonInputWrapperStyles}>
                    <LabelTooltipHorizontal
                      id="automatedTime"
                      label="Auto Time"
                      tooltipMessage="Please enter the automated time that it took to change to the selected state in minutes"
                    />
                    <SpinButton
                      id="automatedTimeInput"
                      defaultValue={value}
                      max={10000}
                      min={0}
                      ariaDescribedBy="automatedTimeLabel"
                      aria-labelledby="automatedTimeLabel"
                      decrementButtonAriaLabel="Decrement automated time taken"
                      incrementButtonAriaLabel="Increment automated time taken"
                      inputProps={{ 'aria-labelledby': 'automatedTimeLabel' }}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        newValue?: string,
                      ): void => {
                        setPageState({
                          ...pageState,
                          autoTime: parseInt(newValue, 10),
                        });
                        return setValue('automatedTime', newValue);
                      }}
                    />
                    <InlineFormInputErrorMessage errorMessage={errors?.automatedTime?.message} />
                  </div>
                )}
              />
              <div style={{ width: 85 }}>
                <Label id="totalTimeLabel">Total</Label>
                <Text block style={{ fontWeight: '400' }}>
                  {`${pageState?.manualTime + pageState?.autoTime} minutes`}
                </Text>
              </div>
            </Stack>
          )}
          {pageState.isTimeTakenRequired && (
            <Stack
              horizontal
              wrap
              styles={{ root: { padding: '20px 0' } }}
              tokens={{ childrenGap: 10 }}
            >
              <Controller
                name="manualTime"
                control={control}
                required
                rules={{
                  required: 'Please provide a value for manual time in minutes',
                  min: {
                    value: 1,
                    message: 'Please provide a value for manual time in minutes',
                  },
                }}
                render={({ value }) => (
                  <div style={spinButtonInputWrapperStyles}>
                    <LabelTooltipHorizontal
                      id="manualTime"
                      label="Manual Time"
                      required
                      tooltipMessage="Please enter the manual time that it took to change to the selected state in minutes"
                    />
                    <SpinButton
                      id="manualTimeInput"
                      defaultValue={value}
                      max={10000}
                      min={0}
                      ariaDescribedBy="manualTimeLabel"
                      aria-labelledby="manualTimeLabel"
                      decrementButtonAriaLabel="Decrement manual time taken"
                      incrementButtonAriaLabel="Increment manual time taken"
                      inputProps={{ 'aria-labelledby': 'manualTimeLabel' }}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        newValue?: string,
                      ): void => {
                        setPageState({
                          ...pageState,
                          manualTime: parseInt(newValue, 10),
                        });
                        return setValue('manualTime', newValue);
                      }}
                    />
                    <InlineFormInputErrorMessage errorMessage={errors?.manualTime?.message} />
                  </div>
                )}
              />
              <Controller
                name="automatedTime"
                control={control}
                render={({ value }) => (
                  <div style={spinButtonInputWrapperStyles}>
                    <LabelTooltipHorizontal
                      id="automatedTime"
                      label="Auto Time"
                      tooltipMessage="Please enter the automated time that it took to change to the selected state in minutes"
                    />
                    <SpinButton
                      id="automatedTimeInput"
                      defaultValue={value}
                      max={10000}
                      min={0}
                      ariaDescribedBy="automatedTimeLabel"
                      aria-labelledby="automatedTimeLabel"
                      decrementButtonAriaLabel="Decrement automated time taken"
                      incrementButtonAriaLabel="Increment automated time taken"
                      inputProps={{ 'aria-labelledby': 'automatedTimeLabel' }}
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        newValue?: string,
                      ): void => {
                        setPageState({
                          ...pageState,
                          autoTime: parseInt(newValue, 10),
                        });
                        return setValue('automatedTime', newValue);
                      }}
                    />
                    <InlineFormInputErrorMessage errorMessage={errors?.automatedTime?.message} />
                  </div>
                )}
              />
              <div style={{ width: 85 }}>
                <Label id="totalTimeLabel">Total</Label>
                <Text block style={{ fontWeight: '400' }}>
                  {`${pageState?.manualTime + pageState?.autoTime} minutes`}
                </Text>
              </div>
            </Stack>
          )}
          {pageState.isReasonMissedSLARequired && (
            <Controller
              id="reasonMissedSLAId"
              name="reasonMissedSLAId"
              rules={{
                required: 'Please provide a missed SLA reason',
              }}
              control={control}
              defaultValue={null}
              render={({ value }) => {
                return (
                  <div>
                    <LabelTooltipHorizontal
                      id="reasonMissedSLAIdLabel"
                      label="Missed SLA Reason"
                      required
                      tooltipMessage="Some or all of selected task(s) are past the due date and require a reason for missed SLA"
                    />
                    <TaskPropertyDropdown
                      ariaLabel="Select reason missed sla"
                      value={value}
                      valueKey="reasonMissedSLAId"
                      taskPropertyName="Missed SLA Reason"
                      required
                      width="100%"
                      handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                        setValue(valueKey, newValue.key);
                      }}
                    />
                    <InlineFormInputErrorMessage
                      errorMessage={errors?.reasonMissedSLAId?.message}
                    />
                  </div>
                );
              }}
            />
          )}
          {pageState.isReasonNotPerformedRequired && (
            <>
              <Controller
                id="reasonNotPerformedId"
                name="reasonNotPerformedId"
                rules={{
                  required: 'Please provide a reason not performed',
                }}
                control={control}
                defaultValue={null}
                render={({ value }) => {
                  return (
                    <div>
                      <LabelTooltipHorizontal
                        id="reasonNotPerformedId"
                        label="Reason Not Performed"
                        required
                        tooltipMessage="Please enter a reason not performed when not performing tasks."
                      />
                      <TaskPropertyDropdown
                        ariaLabel="Select reason not performed"
                        value={value}
                        valueKey="reasonNotPerformedId"
                        taskPropertyName="Reason Not Performed"
                        required
                        width="100%"
                        handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                          setValue(valueKey, newValue.key);
                        }}
                      />
                      <InlineFormInputErrorMessage
                        errorMessage={errors?.reasonNotPerformedId?.message}
                      />
                    </div>
                  );
                }}
              />
            </>
          )}
          {pageState.isReasonReactivatedRequired && (
            <>
              <Controller
                name="reasonReactivatedId"
                control={control}
                errors={errors}
                rules={{
                  required: 'Please provide a reason reactivated',
                }}
                defaultValue={null}
                render={({ value }) => {
                  return (
                    <div>
                      <LabelTooltipHorizontal
                        id="reasonReactivatedId"
                        label="Reason Reactivated"
                        required
                        tooltipMessage="Some or all of the selected tasks are currently in a closed or not performed state and require a reason reactivated"
                      />
                      <TaskPropertyDropdown
                        ariaLabel="Select reason reactivated"
                        valueKey="reasonReactivatedId"
                        taskPropertyName="Reason Reactivated"
                        required
                        value={value}
                        width="100%"
                        handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                          setValue(valueKey, newValue.key);
                        }}
                      />
                      <InlineFormInputErrorMessage
                        errorMessage={errors?.reasonReactivatedId?.message}
                      />
                    </div>
                  );
                }}
              />
            </>
          )}
          {pageState.isNotesAvailable && (
            <Controller
              as={TextField}
              label="Notes (optional)"
              id="notes"
              name="notes"
              control={control}
              resizable={false}
              multiline
              defaultValue=""
            />
          )}
          {selectedState && (
            <MessageBar messageBarType={MessageBarType.warning}>
              <Stack tokens={{ childrenGap: 10 }}>
                <span>{getMessage()}</span>
              </Stack>
            </MessageBar>
          )}

          <LoadingErrorMessage loading={loading} error={error} label="Changing tasks state" />
        </Stack>
        <Stack horizontal styles={{ root: { padding: '20px 0' } }} tokens={{ childrenGap: 30 }}>
          <PrimaryButton
            text={`${
              changeStateMode === ChangeStateMode.ChangeStateCopy ? 'Set task' : 'Change tasks'
            } state`}
            onClick={handleSubmit(submitForm)}
            ariaLabel={`${
              changeStateMode === ChangeStateMode.ChangeStateCopy ? 'Set task' : 'Change tasks'
            } state`}
            disabled={!selectedState || loading}
          />
          <DefaultButton
            disabled={loading}
            text="Cancel"
            onClick={() => {
              setPageState({
                ...pageState,
                isTimeTakenRequired: false,
                isTimeTakenOptional: false,
                isReasonMissedSLARequired: false,
                isReasonNotPerformedRequired: false,
                isReasonReactivatedRequired: false,
                isNotesAvailable: false,
              });
              hideModal();
            }}
            ariaLabel="Cancel"
          />
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};

export default TasksChangeState;
