import React, { useEffect, useState } from 'react';
import {
  ITag,
  FontSizes,
  FontWeights,
  Label,
  Stack,
  TagPicker,
  Text,
  IPickerItemProps,
} from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import { IProjectNileWorkflow } from '../../utils/types/IProjectNileWorkflow';
import { IGroupedChecklistItem } from '../../utils/types/IGroupedChecklistItem';

const labelStyles = {
  fontWeight: FontWeights.semibold,
  fontSize: FontSizes.size14,
  paddingTop: '5px',
  paddingBottom: '5px',
};
const tagItemStyle = {
  root: {
    backgroundColor: 'rgb(237, 235, 233)',
    borderRadius: '2px',
    padding: '5px 12px',
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    margin: '2px',
  },
};
const tagItemTextStyle = {
  root: {
    minWidth: 40,
    margin: '0px 5px',
    wordBreak: 'break-all', // This will break words at any character to wrap text
    overflowWrap: 'break-word', // This will break words at the end of the line to wrap text
  },
};
export const mapWorkflowToTag = (workflow: IProjectNileWorkflow): IGroupedChecklistItem => {
  return {
    id: workflow.id,
    key: workflow.id,
    name: workflow.name,
    selected: workflow.selected,
  } as ITag;
};

export const mapTagsToWorkflows = (tags: IGroupedChecklistItem[]): IProjectNileWorkflow[] => {
  return tags?.map((workflowTag) => {
    return {
      id: workflowTag.key,
      name: workflowTag.name,
      selected: workflowTag.selected,
    } as IProjectNileWorkflow;
  });
};

export const mapWorkflowsToTags = (workflows: IProjectNileWorkflow[]): IGroupedChecklistItem[] => {
  return workflows?.map((workflow) => {
    return mapWorkflowToTag(workflow);
  });
};

export const mapNileWorkflowNoTypename = (
  nileWorkflows: IProjectNileWorkflow[],
): IProjectNileWorkflow[] => {
  return nileWorkflows?.map((workflow) => {
    return {
      key: workflow.id,
      name: workflow.name,
      id: workflow.id,
      selected: workflow?.selected,
    };
  });
};

export interface INileWorkflowTagPickerProps {
  selectedWorkflows: IProjectNileWorkflow[];
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  onInputClicked?: () => void;
}

const NileWorkflowTagPicker = (props: INileWorkflowTagPickerProps): JSX.Element => {
  const { selectedWorkflows, errorMessage, onInputClicked, label, disabled, placeholder } = props;
  const [selectedNileWorkflowTags, setSelectedNileWorkflowTags] = useState<ITag[]>(
    mapWorkflowsToTags(selectedWorkflows),
  );

  useEffect(() => {
    setSelectedNileWorkflowTags(mapWorkflowsToTags(selectedWorkflows));
  }, [selectedWorkflows]);

  return (
    <Stack
      styles={{
        root: {
          width: '100%',
        },
      }}
    >
      {label && (
        <Label style={labelStyles} disabled={disabled}>
          {label}
        </Label>
      )}
      <div
        role="button"
        onClick={onInputClicked}
        // Accessibility Properties:
        aria-label={label || 'Value'} // Set the aria-label to the label or 'Value'
        tabIndex={0} // Make the div focusable
        onKeyDown={(event) => {
          // Allow the div to be clicked with the keyboard
          if (event.key === 'Enter' || event.key === ' ') {
            onInputClicked();
          }
        }}
        style={{
          width: '100%',
        }}
      >
        <TagPicker
          onInputChange={() => ''} // Do nothing for input as it is disabled
          onResolveSuggestions={() => []} // Do not resolve any suggestions
          selectedItems={selectedNileWorkflowTags}
          inputProps={{
            id: 'nileWorkflows',
            'aria-label': label || 'Value',
            placeholder: placeholder || 'Select a Workflow',
            required: true,
            tabIndex: -1, // Prevent tabbing into the input manually
          }}
          onRenderItem={(pickerProps: IPickerItemProps<ITag>) => {
            return (
              <Stack horizontal styles={tagItemStyle}>
                <Text styles={tagItemTextStyle}>{pickerProps?.item?.name}</Text>
              </Stack>
            );
          }}
          styles={{
            root: { pointerEvents: 'none' },
          }}
        />
      </div>
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </Stack>
  );
};

export default NileWorkflowTagPicker;
