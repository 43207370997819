import config from './ConfigLoader';
import { LocalStorageKeys } from './hooks/useLocalStorage';

export const stripTrailingSlash = (str: string): string => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};
export const getParentUriSegment = (routeString: string): string => {
  const uriArray = stripTrailingSlash(routeString).split('/');
  uriArray.pop();
  const parentUri = uriArray.join('/');
  return stripTrailingSlash(parentUri);
};

export const retrieveUrl = (goToUrl: string, depth = 1, match: { url: string }) => {
  if (goToUrl) {
    return goToUrl;
  }
  let newUrl = match.url;
  for (let i = 0; i < depth; i += 1) {
    newUrl = getParentUriSegment(newUrl);
  }
  return newUrl;
};

export const deepCopy = <T extends object>(itemToCopy: T): T => {
  return JSON.parse(JSON.stringify(itemToCopy));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertDotPathToNestedObject = (path: string, value: any) => {
  if (path) {
    const [last, ...paths] = path.split('.').reverse();
    return paths.reduce((acc, el) => ({ [el]: acc }), { [last]: value });
  }
  return {};
};

export const bustCacheIfNeeded = () => {
  const configLocalStorageVersion = config?.settings?.localStorageVersion;
  const localStorageVersion = window.localStorage.getItem(LocalStorageKeys.localStorageVersion);

  if (parseInt(configLocalStorageVersion, 10) === parseInt(localStorageVersion, 10)) return;

  // If versions mismatched bust cache and set with config value
  window.localStorage.clear();
  window.localStorage.setItem(LocalStorageKeys.localStorageVersion, configLocalStorageVersion);
};

export const truncateString = (str: string, limit: number): string => {
  if (str.length <= limit) {
    return str;
  }
  return `${str.slice(0, limit)}...`;
};

export const isGuid = (value: string): boolean => {
  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  const stringIsGuid = guidRegex.test(value);
  return stringIsGuid;
};
