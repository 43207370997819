import { gql } from '@apollo/client';
import { OPS_DETAIL_BASE_FRAGMENT } from './HierarchiesApi';
import { baseTaskProps } from './StagedTaskApi';

const DRAFT_TASK_PREVIEW_QUERY = gql`
  query draftTaskPreviewCounts($draftTaskGeneratorDto: DraftTaskGeneratorDtoInput!) {
    draftTaskPreviewCounts(draftTaskGeneratorDto: $draftTaskGeneratorDto) {
      publishTypeCounts {
        publishType
        taskCount
      }
      notDraftedCounts {
        repititionValue
        taskCount
      }
      inActiveStagedTaskIds
      tasksInPublishedState
      jeTasksWithNoJemChecklistTaskIds
    }
  }
`;

const DRAFT_TASK_FRAGMENT = gql`
  fragment DraftTaskFull on DraftTaskDto {
    ${baseTaskProps}
    dueDate
    stagedTaskId
    publishedTaskNotes
    stagedTask {
      isActive
    }
  }
  ${OPS_DETAIL_BASE_FRAGMENT}
`;

export const EditDraftTask = gql`
  mutation editDraftTask($draftTaskDto: TaskSingleInputDtoInput!) {
    editDraftTask(draftTaskDto: $draftTaskDto) {
      ...DraftTaskFull
    }
  }
  ${DRAFT_TASK_FRAGMENT}
`;

export const EditDraftTaskBulk = gql`
  mutation editDraftTaskBulk($itemBulkDto: TaskBulkInputDtoInput!) {
    editDraftTaskBulk(itemBulkDto: $itemBulkDto) {
      ...DraftTaskFull
    }
  }
  ${DRAFT_TASK_FRAGMENT}
`;
export const EditDraftTaskRemoveBulk = gql`
  mutation editDraftTaskRemoveBulk($itemBulkDto: TaskBulkInputDtoInput!) {
    editDraftTaskRemoveBulk(itemBulkDto: $itemBulkDto) {
      ...DraftTaskFull
    }
  }
  ${DRAFT_TASK_FRAGMENT}
`;

export const GetDraftTasks = gql`
  query DraftTasks(
    $keyword: String
    $filters: TaskFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [DraftTaskDtoSortInput!]
  ) {
    draftTasks(
      keyword: $keyword
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          ...DraftTaskFull
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DRAFT_TASK_FRAGMENT}
`;

export const GetDraftTask = gql`
  query DraftTask($draftTaskId: Int!) {
    draftTask(draftTaskId: $draftTaskId) {
      ...DraftTaskFull
    }
  }
  ${DRAFT_TASK_FRAGMENT}
`;
export const GetDraftTasksSearch = gql`
  query DraftTasks($keyword: String!) {
    draftTasks(keyword: $keyword) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GenerateDraftTasksMutation = gql`
  mutation generateDraftTasks($generateDraftTasksDto: DraftTaskGeneratorDtoInput!) {
    generateDraftTasks(generateDraftTasksDto: $generateDraftTasksDto) {
      ...DraftTaskFull
    }
  }
  ${DRAFT_TASK_FRAGMENT}
`;

export const RemoveDraftTasksMutation = gql`
  mutation removeDraftTaskBulk($itemBulkDto: TaskBulkInputDtoInput!) {
    removeDraftTaskBulk(itemBulkDto: $itemBulkDto)
  }
`;

export default DRAFT_TASK_PREVIEW_QUERY;
