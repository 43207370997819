import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { TaskType } from '../../components/tasks/list/BaseTaskListfilters.config';
import { JEMTaskType } from '../../components/tasks/PublishedTasks.config';
import { useFeatureFlag } from '../../components/featureFlags/FeatureFlagged';

const publishTypeOptions: IDropdownOption[] = [
  { key: 'SINGLE', text: 'Single' },
  { key: 'GLOBAL', text: 'Global' },
  { key: 'MULTI_CC', text: 'Multi-CC' },
];

interface IPublishTypeDropdownProps extends IFieldsetProps {
  taskType?: TaskType;
  hideOptions?: string;
}

const PublishTypeDropdown = (props: IPublishTypeDropdownProps): JSX.Element => {
  const {
    label,
    required,
    disabled,
    value,
    handleChange,
    valueKey,
    errorMessage,
    taskType,
    hideOptions,
  } = props;

  let options = publishTypeOptions;
  const isFeatureFlagEnabled = useFeatureFlag('JEMChecklistIntegration');
  if (taskType === TaskType.StagedTasks) {
    options = isFeatureFlagEnabled
      ? publishTypeOptions
      : [...publishTypeOptions, { key: 'JEM_CHECKLIST', text: 'JEM-Checklist' }];
  }

  if (hideOptions === JEMTaskType.JEMChecklists) {
    options = options.map((option) =>
      option.key !== 'SINGLE' ? { ...option, disabled: true } : option,
    );
  } else if (hideOptions === JEMTaskType.JEBulk) {
    options = options.map((option) =>
      option.key === 'MULTI_CC' ? { ...option, disabled: true } : option,
    );
  }

  return (
    <Dropdown
      errorMessage={errorMessage}
      required={required}
      label={label}
      ariaLabel={label}
      options={options}
      disabled={disabled}
      selectedKey={value}
      placeholder="Select publish type"
      onChange={(event, item): void => {
        handleChange(item, valueKey);
      }}
    />
  );
};

export default PublishTypeDropdown;
